<template>
  <div>
    <div v-if="this.$route.name === 'PurchaseReport'">
      <PurchaseReport />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import PurchaseReport from "@/views/companyApp/purchaseReport/PurchaseReport.vue";

export default {
  name: "PurchaseReportParent",
  components: {
    PurchaseReport,
  },
};
</script>
