<template>
  <div>
    <v-btn
      small
      class="mb-1 mx-2"
      color="primary"
      :disabled="!data"
      outlined
      @click="downloadExcelFile"
    >
      <v-icon left>mdi-microsoft-excel</v-icon>
      {{ $t("quotation.excel") }}
    </v-btn>
    <v-btn
      class="float-end"
      outlined
      small
      @click="resetFilters"
      color="primary"
    >
      <v-icon left> mdi-restore </v-icon>
      {{ $t("quotation.resetFilter") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ExcelAndResetFilterCommon",
  props: ["data"],
  methods: {
    downloadExcelFile() {
      this.$emit("downloadExcelFile");
    },
    resetFilters() {
      this.$emit("resetFilters");
    },
  },
};
</script>
